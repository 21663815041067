import type { Deal } from '../../../types/Deal';

export default (deal: Deal, params: Record<string, string>, timeType: string): number | null => {
  // Allow forcing an end time/start time with the 'end_time' or 'start_time' url param
  // added for easier testing
  // E.g. '2017/10/31 09:50:00'
  // Otherwise use a deal end time/start time (if it exists)
  if (params[timeType]) {
    return new Date(params[timeType]).getTime() / 1000;
  }
  if (deal && deal[timeType]) {
    return deal[timeType];
  }
  return null;
};
