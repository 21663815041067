import React from 'react';

import classNames from 'classnames';

import getUrlParameters from '../../../../utils/getUrlParameters';
import { loadCSS } from '../../../../utils/loadCSS';
import type { Deal } from '../../../types/Deal';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import getDealTimes from './getDealTimes';
import styles from './styles/deal-countdown.css';
import useDealCountdownTimeString from './useDealCountdownTimeString';

interface DealCountdownProps {
  deal: Deal;
  className: string;
}

loadCSS('deal-countdown');
const DealCountdown: React.FC<DealCountdownProps> = ({ deal, className }) => {
  const { translate, language, area } = useHawkWidgetContext();

  const params = getUrlParameters(['end_time', 'start_time']);
  const dealEndTime = getDealTimes(deal, params, 'end_time');
  const dealStartTime = getDealTimes(deal, params, 'start_time');
  const { timeString, label, status } = useDealCountdownTimeString(
    dealStartTime,
    dealEndTime,
    translate,
    language,
    area,
  );

  return (
    <div
      className={classNames({
        [styles[status]]: styles[status],
        [styles[className]]: styles[className],
      })}
    >
      {label ? <span className={styles['label']}>{label}</span> : null}
      <span className={styles['time-string']}>{timeString}</span>
    </div>
  );
};

export default DealCountdown;
